import { Title } from '@solidjs/meta';
import {
	BreadcrumbItem,
	Breadcrumbs,
	Button,
	Container,
	Errors,
	Field,
	Heading,
	Input,
	Label,
	Option,
	Page,
	Section,
	Select,
	TextField,
} from '@troon/ui';
import { Show, For, createSignal } from 'solid-js';
import { useSubmission } from '@solidjs/router';
import { Formspree, formspreeAction, useFormspree } from '@troon/forms';
import { Icon } from '@troon/icons';
import dayjs from '@troon/dayjs';
import { Grid, GridFive, GridSeven } from '../../components/layouts/grid';
import { getConfigValue } from '../../modules/config';
import { useUser } from '../../providers/user';

export default function MilitaryAndFirstRespondersAccess() {
	const formAction = useFormspree(action);
	const submission = useSubmission(formAction);
	const user = useUser();
	const [subItems, setSubItems] = createSignal<(typeof statuses)[keyof typeof statuses]>();

	return (
		<>
			<Title>Military and First Responders | Troon Access | Troon</Title>
			<Container>
				<div class="pt-4">
					<Breadcrumbs>
						<BreadcrumbItem href="/access">Troon Access</BreadcrumbItem>
						<BreadcrumbItem href="/access/military-and-first-responders">Military and First Responders</BreadcrumbItem>
					</Breadcrumbs>
				</div>

				<Page>
					<Grid>
						<GridFive>
							<Section>
								<Heading as="h1" size="h2">
									Military and First Responders
								</Heading>
								<p>
									This offer is available to military personnel who have served or are currently serving in the United
									States Armed Forces, as well as first responders and emergency personnel including law enforcement,
									EMTs, paramedics, and firefighters. After your information is verified, you will receive an email with
									a promo code to use for a $100 discount on Troon Access or Troon Access+.
								</p>
							</Section>
						</GridFive>

						<GridSeven class="rounded border border-neutral bg-neutral-100 p-4 md:p-8">
							<Show
								when={!submission.result}
								fallback={
									<div class="container mx-auto flex max-w-xl flex-col items-center gap-6 text-center">
										<Icon name="circle-check-custom" class="text-8xl" />
										<Heading as="h1" size="h2">
											Request received!
										</Heading>
										<p class="text-lg">Thanks for contacting us! We will get in touch with you shortly.</p>
									</div>
								}
							>
								<Formspree action={formAction} class="flex flex-col gap-6">
									<div class="flex w-full flex-wrap gap-6">
										<TextField name="First name" required class="grow">
											<Label>First name</Label>
											<Input autocomplete="given-name" value={user()?.me.firstName} />
										</TextField>

										<TextField name="Last name" required class="grow">
											<Label>Last name</Label>
											<Input autocomplete="family-name" value={user()?.me.lastName} />
										</TextField>
									</div>

									<TextField name="Email" required>
										<Label>Email address</Label>
										<Input autocomplete="email" inputMode="email" value={user()?.me.email} />
									</TextField>

									<TextField name="Date of birth" required>
										<Label>Date of birth</Label>
										<Input
											type="date"
											min={dayjs().subtract(124, 'year').startOf('year').format('YYYY-MM-DD')}
											max={dayjs().format('YYYY-MM-DD')}
										/>
									</TextField>

									<Field name="Status" required>
										<Label>Status</Label>
										<Select
											onChange={(e) => {
												const val = e.currentTarget.value;
												setSubItems(statuses[val] || null);
											}}
										>
											<Option disabled selected class="hidden" />
											<For each={Object.keys(statuses)}>{(status) => <Option>{status}</Option>}</For>
										</Select>
									</Field>

									<Show when={typeof subItems() !== 'undefined'}>
										<Show
											when={subItems()}
											fallback={
												<TextField name="Organization" required>
													<Label>Organization</Label>
													<Input />
												</TextField>
											}
										>
											{(more) => (
												<>
													<Field name={more().label}>
														<Label>{more().label}</Label>
														<Select>
															<Option disabled selected class="hidden" />
															<For each={more().items}>{(item) => <Option>{item}</Option>}</For>
														</Select>
													</Field>

													<Show when={more().discharge}>
														<TextField name="Date of discharge" required>
															<Label>Date of discharge</Label>
															<Input
																type="date"
																min={dayjs().subtract(124, 'year').startOf('year').format('YYYY-MM-DD')}
																max={dayjs().format('YYYY-MM-DD')}
															/>
														</TextField>
													</Show>
												</>
											)}
										</Show>
									</Show>

									<Errors />

									<Button type="submit" class="w-fit">
										Verify my status
									</Button>
								</Formspree>
							</Show>
						</GridSeven>
					</Grid>
				</Page>
			</Container>
		</>
	);
}

const action = formspreeAction(getConfigValue('FORMSPREE_PROJECT_ID'), 'military-first-responder-offers');

const reserves = {
	label: 'Branch of service',
	items: [
		'Air Force Reserve',
		'Air National Guard',
		'Army National Guard',
		'Army Reserve',
		'Coast Guard Reserve',
		'Marine Corps Forces Reserve',
		'Navy Reserve',
	],
};

const active = {
	label: 'Branch of service',
	items: [
		'Air Force',
		'Army',
		'Coast Guard',
		'Marine Corps',
		'National Oceanic and Atmospheric Administration',
		'Navy',
		'Other DoD',
		'Public Health Service Commisioned Corps',
		'Space Force',
	],
};
const statuses: Record<string, null | { label: string; items: Array<string>; discharge?: boolean }> = {
	'Active Duty': active,
	'Military Retiree': { ...active, discharge: true },
	'Military Veteran or Retiree': { ...active, discharge: true },
	'Reservist or National Guard': reserves,
	EMT: null,
	Firefighter: null,
	Police: null,
};
